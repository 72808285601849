import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { Logout } from 'src/app/store/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { NavbarService } from '../navbar/navbar.service';
import { TitleService } from '../../../shared/service/title-service';
import { ProfileService } from 'src/app/modules/profile/shared/profile.service';
import { PermissionService } from '../../services/permission.service';
import { featureFlag } from 'src/environments/environment';
import { versionInfo } from '../../../../version-info';
import { CertificateService } from 'src/app/modules/certificate/service/certificate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  navbarActive = false;
  fullWidth: number;
  flags;
  selectedFlags: string;
  profile: any;
  isAdmin = false;
  notificationsUnread: Array<any>;
  notificationsRead: Array<any>;
  notificationResult;
  notificationHeader: string;
  showNotificationToggle = false;
  showWarning = true;
  getCertificateStatusFirstTime = true;
  certificateStatus: string = null;
  earlier: string;
  loadMore: string;
  translateSidebar;
  page = 1;
  isLoading = false;
  typeNotification = {
    tag_comment_on_memo: 'memo_comment_tag',
    comment_reply_on_memo: 'memo_comment_reply',
    new_announcement: 'memo_announced',
    new_post_department: 3,
    new_post_public: 4,
    new_post_and_mention: 5,
    new_comment: 6,
    new_comment_mention: 7,
    new_comment_tag_comment: 8,
    memo_new_req_for_approval: 9,
    memo_update_status: 10,
  };

  checkLanguage = false;
  themeList: ThemeList;
  hoverList = {
    logout: false,
  };

  language: string;
  isFullSidebar = true;
  departmentSubMenu = true;
  manageUploadSubMenu = true;
  openSidebar = false;
  backgroundFade = null;
  usageInformation = true;
  packageAddition = true;
  manageCertificate = true;
  manageFolder = true;
  version = versionInfo.hash;

  certificateDetail = null;
  showWarningCertificate = true;
  folderName: string;
  folderList: FolderList[];

  externalLink = {
    faq: 'https://cpfito365.sharepoint.com/:u:/s/SAPERPProject/EZserTifNR9Kmz9FO_kBPowBDqImP_6Pu_DfIr8yZVvfLg?e=tbBXun',
    feedback: 'https://scrm.cpf.co.th/',
    rating: 'https://forms.office.com/r/8WcpueMG8r',
  };

  checkIsTemplate = false;
  isFolderId = null;
  folderNameChange: string;
  folderId: number;
  folderDetail = null;
  private dataSubscription: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private alert: AlertService,
    private profileService: ProfileService,
    private permission: PermissionService,
    private store: Store,
    private titleService: TitleService,
    private certificateService: CertificateService,
    private activateRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memoService: MemoService,
    private location: Location,
  ) {
    this.memoService.onSidebar(this.isFullSidebar);
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.openSidebar = false;
        this.checkIsActiveTemplate();
        this.getFolderList();
      }
    });

    this.memoService.checkQuery.subscribe(() => {
      this.checkIsActiveTemplate();
      this.getFolderList();
    });

    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
      this.hexToRGBA();
    });
    this.titleService.getSidebar().subscribe(() => {
      this.openSidebar = !this.openSidebar;
      this.isFullSidebar = true;
      this.memoService.onSidebar(this.isFullSidebar);
    });

    this.store.subscribe((state) => {
      this.profile = state.auth;

      if (state?.auth?.preferred_timezone_hours) {
        localStorage.setItem(
          'preferred_timezone_hours',
          state?.auth?.preferred_timezone_hours,
        );
      }
      if (
        featureFlag.ddoc &&
        this.getCertificateStatusFirstTime &&
        this.profile &&
        this.profile.id &&
        this.permission.checkAccess(['General'])
      ) {
        this.getCertificateStatusFirstTime = false;
        this.profileService
          .loadCertificateStatus()
          .subscribe((data: any) => {
            // TODO - It's unused
            // tslint:disable-next-line:no-shadowed-variable
            const _ = data.certificate_id;
            this.certificateStatus = data.certificate_status;
          });
      }
    });
  }

  ngOnInit(): void {
    $('#menu-toggle').click((e) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedFlags = localStorage.getItem('lang');
    this.translateSidebar = this.translate;
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateNotification();
      },
    );
    this.notificationService.updateNotificationCount();
    this.getAllNotification();
    this.translateNotification();
    this.checkRouteForSubmenu();
    this.getSealing();
    this.checkIsActiveTemplate();
    this.getFolderList();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  hexToRGBA(): void {
    let hex = this.themeList.hovered_text_color;
    const opacity = 0.1;
    const rgb =
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map((l) => {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')';
    this.backgroundFade = rgb;
  }

  checkRouteForSubmenu(): void {
    const manageOrg = [
      { url: 'department' },
      { url: 'users' },
      { url: 'loa' },
      { url: 'cc-group' },
    ];

    const usage = [
      { url: 'kpi-dashboard' },
      { url: 'usage-dashboard' },
      { url: 'memo-report' },
      { url: 'operation-log' },
    ];

    const certificate = [
      { url: 'manage-csr' },
      { url: 'certificate' },
    ];
    const url = this.router.url.split('/')[1];
    let checkPathOrg = null;
    let checkPathUsageInformation = null;
    let checkPathCertificate = null;
    checkPathOrg = manageOrg.some((res) => res.url === url);
    checkPathUsageInformation = usage.some((res) => res.url === url);
    checkPathCertificate = certificate.some((res) => res.url === url);
    if (checkPathOrg) {
      this.departmentSubMenu = true;
    }
    if (url === 'manage-types-upload-memo') {
      this.manageUploadSubMenu = true;
    }
    if (checkPathUsageInformation) {
      this.usageInformation = true;
    }

    if (checkPathCertificate) {
      this.manageCertificate = true;
    }
  }

  checkRouteActive(url: string): boolean {
    return url.split('/')[1] === this.router.url.split('/')[1];
  }

  translateNotification(): void {
    this.notificationHeader = this.translate.instant(
      'NAV-BAR.NOTIFICATION',
    );
    this.earlier = this.translate.instant('NAV-BAR.EARLIER');
    this.loadMore = this.translate.instant('NAV-BAR.LOAD-MORE');
    if (this.translate.currentLang === 'en') {
      this.checkLanguage = true;
    } else {
      this.checkLanguage = false;
    }
  }

  getAllNotification(): void {
    this.notificationService
      .getAllNotification({ page: 1 })
      .subscribe(
        (notification) => {
          this.notificationResult = notification;
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
  }

  canCreateUser(): boolean {
    return this.router.url === '/permission/profile/list';
  }

  navigateTo(): void {
    this.router.navigate(['/department', 'level']);
  }

  openNotification(): void {
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification(): void {
    this.showNotificationToggle = false;
  }

  // Notification
  clickNotification(notification): void {
    this.notificationService.updateIsRead(notification.id).subscribe(
      () => {
        _.forEach(this.notificationResult.results, (obj) => {
          if (notification.id === obj.id && obj.read === false) {
            obj.read = true;
            this.notificationResult.unread_count =
              this.notificationResult.unread_count - 1;
          }
        });
        [this.notificationsUnread, this.notificationsRead] =
          _.partition(this.notificationResult.results, [
            'read',
            false,
          ]);
      },
      (err) => {
        this.swal.toastNotification({
          type: 'error',
          content: err.status,
        });
      },
    );
    this.notificationRouter(notification);
  }

  notificationRouter(notification): void {
    this.showNotificationToggle = !this.showNotificationToggle;
    if (
      notification.notification.source_content_type_name === 'memo'
    ) {
      if (notification.action === 'memo_pending') {
        return this.navigateToPage(
          '/executive/list/',
          notification.notification.source_id,
        );
      } else {
        return this.navigateToPage(
          '/memo/memo/real/list/',
          notification.notification.source_id,
        );
      }
    } else if (
      notification.notification.source_content_type_name ===
      'announcement'
    ) {
      this.router.navigate(['/feed', 'list']);
    } else if (
      notification.notification.source_content_type_name ===
      'newsfeedpost'
    ) {
      this.navigateToPage(
        '/feed/list/',
        notification.notification.source_id,
      );
    }
  }

  navigateToPage(url: string, id?: number): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/', url + id ? id : '']);
      });
  }

  loadMoreNotification(): void {
    if (
      this.notificationResult.results.length <
      this.notificationResult.count
    ) {
      this.isLoading = true;
      this.page += 1;
      const params = { page: this.page.toString() };
      this.notificationService.getAllNotification(params).subscribe(
        (res) => {
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
    }
  }

  hoverText(type, value): void {
    this.hoverList[type] = value;
  }

  openFullSidebar() {
    this.isFullSidebar = !this.isFullSidebar;
    this.manageFolder = !this.manageFolder;
    this.manageFolder = this.isFullSidebar && this.manageFolder;
    this.memoService.onSidebar(this.isFullSidebar);
  }

  clickOpenFolder(): void {
    this.manageFolder = !this.manageFolder;
    this.isFullSidebar = this.isFullSidebar || this.manageFolder;
    this.memoService.onSidebar(this.isFullSidebar);
  }

  getSealing(): void {
    const allRole = ['Master Admin', 'Admin', 'IT'];
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth?.role,
    );
    const checkRole = allRole.some((e) => e === role);
    if (checkRole) {
      this.certificateService.getSealing().subscribe((res: any) => {
        this.certificateDetail = res;
      });
    }
  }

  redirectToExternalUrl(type): void {
    window.location.href = this.externalLink[type];
  }

  checkIsActiveTemplate() {
    this.isFolderId = null;
    this.activateRoute.params.subscribe((params) => {
      if (params?.folder_id && params?.folder_id !== undefined) {
        this.isFolderId = Number(params.folder_id);
      }
    });
    const res =
      this.activateRoute.snapshot.queryParamMap.get('returnQuery');

    let obj;
    try {
      obj = JSON.parse(decodeURIComponent(escape(atob(res))));
    } catch (error) {}
    if (obj?.type_document === 'template' && !obj?.isFromTemplate) {
      this.checkIsTemplate = true;
    } else {
      this.checkIsTemplate = false;
    }

    const link = this.router.url.split('/');
    if (link[2] === 'template') {
      this.checkIsTemplate = true;
    }

    if (link[2]?.split('?')[0] === 'template') {
      this.checkIsTemplate = true;
    }
    if (obj?.folder_id && obj?.folder_id !== undefined) {
      this.isFolderId = Number(obj?.folder_id);
    }
  }
  getFolderList(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = this.memoService
      .getFolder()
      .subscribe((res: FolderList[]) => {
        this.folderList = res;
      });
  }

  openFolderModal(content): void {
    this.modalService.open(content, { centered: true });
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  createFolder(): void {
    const data = {
      name: this.folderName ? this.folderName : 'Folder',
    };
    this.memoService.createFolder(data).subscribe(
      (res: any) => {
        this.folderName = null;
        this.getFolderList();
        this.modalService.dismissAll();
        this.alert.success(
          this.translate.instant(
            'FOLDER.Successfully created folder',
          ),
        );
        const queryParams = btoa(
          unescape(
            encodeURIComponent(
              JSON.stringify({
                folder_id: res.id,
                folder_name: res.name,
              }),
            ),
          ),
        );
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['memos', 'folder'], {
              queryParams: {
                returnQuery: queryParams,
              },
            });
          });
      },
      (error) => {
        this.alert.error(error.error.name[0]);
      },
    );
  }

  navigateToPageFolder(id?: number, name?: string, event?): void {
    const target = event?.target as HTMLElement;
    const classList = target?.classList;
    if (
      // ng-select option clicked
      classList?.contains('fa-ellipsis-v') ||
      classList?.contains('item-folder')
    ) {
      return;
    }
    const res =
      this.activateRoute.snapshot.queryParamMap.get('returnQuery');
    const queryParams = btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify({
            folder_id: id,
            folder_name: name,
          }),
        ),
      ),
    );
    if (res === queryParams) {
      return;
    }
    this.isFolderId = id;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['memos', 'folder'], {
          queryParams: {
            returnQuery: queryParams,
          },
        });
      });
  }

  openModal(content, folder): void {
    this.folderDetail = folder;
    this.folderNameChange = folder.name;
    this.folderId = folder.id;
    this.modalService.open(content, { centered: true });
  }

  editNameFolder(): void {
    const data = {
      name: this.folderNameChange,
    };
    this.memoService.editFolder(this.folderId, data).subscribe(
      (res: any) => {
        this.folderDetail = null;
        this.getFolderList();
        this.modalService.dismissAll();
        this.alert.success(
          this.translate.instant(
            'FOLDER.Successfully renamed folder name',
          ),
        );
        this.navigateToPageFolder(res.id, res.name);
      },
      (error) => {
        this.alert.error(error.error.name[0]);
      },
    );
  }

  deleteFolder(): void {
    const data = {
      name: this.folderNameChange,
    };
    this.memoService.deleteFolder(this.folderId, data).subscribe(
      () => {
        this.folderDetail = null;
        this.modalService.dismissAll();
        this.alert.success(
          this.translate.instant(
            'FOLDER.Successfully deleted folder',
          ),
        );
        this.getFolderList();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/memos/']);
          });
      },
      (error) => {
        this.alert.error(error);
      },
    );
  }

  closeFolderModal(): void {
    this.folderName = null;
    this.folderNameChange = null;
    this.modalService.dismissAll();
  }
}

interface Result {
  next: string;
  previous?: string;
  count: number;
  results: Notification[];
  unread_count: number;
  read_count: number;
}

export interface FolderList {
  id: number;
  updated_at: string;
  name: string;
}
