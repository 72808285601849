<div class="d-flex"
    id="wrapper">
  <!-- Sidebar -->
  <div class="width-sidebar"
      id="sidebar-wrapper"
      [ngClass]="{'position-sidebar':openSidebar,'hidden-sidebar':!openSidebar, 
      'width-sidebar-full': !isFullSidebar}"
      [ngStyle]="{
        background:
          'linear-gradient(to top, ' +
          themeList?.bg_color_primary +
          ', ' +
          themeList?.bg_color_secondary +
          ' 60%)',
        color: themeList?.text_color
      }">
    <div class="sidebar-heading d-flex">
      <div class="pointer mx-2 position-relative"
          [ngClass]="openSidebar? 'd-none':''"
          [ngStyle]="{'color': themeList?.text_color, 'min-width': isFullSidebar? '0':'50px'}">
        <i class="custom-hamburger position-burger fa fa-bars"
            [ngStyle]="{'right': isFullSidebar? '-30px':'10px'}"
            aria-hidden="true"
            (click)="openFullSidebar()"></i>
      </div>
      <div class="flex-grow-1 pl-2">
        <img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
            style="max-height: 50px;">
      </div>
      <span class="show-close pointer pr-4 text-right"
          (click)="openSidebar = !openSidebar"><i class="fas fa-times"></i></span>
    </div>

    <!-- /#sidebar-wrapper -->
    <div class="list-group list-group-flush pt-4"
        [ngClass]="isFullSidebar ? 'text-left':'text-center'">

      <div class="menu-main overflow-auto">

        <!-- Approval -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'pending-approval']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['General', 'HR']"
            ngbTooltip="{{'SIDEBAR.PENDING' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action notification px-4">
            <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.PENDING'|translate}}
            </span>
            <span class="badge-number d-inline-block"
                *ngIf="notificationService.memoNotificationCount > 0">
              {{ notificationService.memoNotificationCount > 99 ? '99+' : notificationService.memoNotificationCount }}
            </span>
          </a>
        </div>

        <!-- Management -->
        <div class="custom-border custom-border-top"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [ngStyle]="{'background': departmentSubMenu? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              ngbTooltip="{{'SIDEBAR.MANAGEMENT'|translate}}"
              (click)="departmentSubMenu=!departmentSubMenu">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <svg-icon src="assets/svg/icon-menu/org.svg"
                  class="mt-title-svg"
                  [stretch]="true"
                  [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20,
                    'margin-left.px': isFullSidebar? 0: 9
                  }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">
                {{'SIDEBAR.MANAGEMENT'|translate}}
              </span>
              <div class="text-right pl-2"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far fa-angle-down"
                    *ngIf="!departmentSubMenu"></i>
                <i class="far fa-angle-up"
                    *ngIf="departmentSubMenu"></i>
              </div>
            </a>
          </div>
          <ng-container *ngIf="departmentSubMenu">

            <!-- Manage Users -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'users']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <!-- Manage Users -->
                <svg-icon src="assets/svg/icon-menu/user--profile.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MANAGE-USERS'|translate}}
                </span>
              </a>
            </div>

            <!-- Email Group-->
            <div routerLinkActive="active"
                [routerLink]="['/', 'email-group']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                ngbTooltip="{{'SIDEBAR.EMAIL-GROUP' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <!-- Email Group -->
                <svg-icon src="assets/svg/icon-menu/email-group.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.EMAIL-GROUP'|translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Certificate -->
        <ng-container *appFeatureFlag="'ddoc'">
          <div class="custom-border"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [ngStyle]="{'background': manageCertificate? '': '' , 'color': themeList?.text_color}">
            <!-- manage certificate -->
            <div routerLinkActive="active"
                class="mt-2 hasSubMenu"
                *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                placement="right"
                ngbTooltip="{{'CSR.CERTIFICATE'|translate}}"
                (click)="manageCertificate=!manageCertificate">
              <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
                <svg-icon src="assets/svg/icon-menu/document.svg"
                    class="mt-title-svg"
                    [stretch]="true"
                    [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20,
                    'margin-left.px': isFullSidebar? 0: 9
                  }">
                </svg-icon>
                <span class="w-100 f-size-17 pt-1 pl-2"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{'color': themeList?.text_color}">{{'CSR.CERTIFICATE'|translate}}</span>
                <div class="text-right"
                    [ngStyle]="{'color': themeList?.text_color}">
                  <i class="far fa-angle-down"
                      *ngIf="!manageCertificate"></i>
                  <i class="far fa-angle-up"
                      *ngIf="manageCertificate"></i>
                </div>
              </a>
            </div>

            <!-- manage csr -->
            <ng-container *ngIf="manageCertificate">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'manage-csr']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin', 'IT']"
                  ngbTooltip="{{'CSR.CSR-LIST' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/carbon_request-quote.svg"
                      [stretch]="true"
                      [svgStyle]="{ 'fill': (rla.isActive ?
                  themeList?.hovered_text_color: themeList?.text_color), 'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                color: rla.isActive
                  ? themeList?.hovered_text_color
                  : themeList?.text_color
              }">
                    {{'CSR.CSR-LIST' | translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- Manage Certificate -->
            <ng-container *ngIf="manageCertificate">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'certificate']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <!-- Manage Certificate -->
                  <svg-icon src="assets/svg/icon-menu/certificate.svg"
                      [stretch]="true"
                      [svgStyle]="{
              'fill': rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color,
              'height.px': 20,
              'width.px': 20
            }">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
              color: rla.isActive
                ? themeList?.hovered_text_color
                : themeList?.text_color
              }">
                    {{'SIDEBAR.CERTIFICATE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Usage Information -->
        <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [ngStyle]="{'background': usageInformation? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              ngbTooltip="{{'SIDEBAR.USAGE-INFORMATION'|translate}}"
              (click)="usageInformation=!usageInformation">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <svg-icon src="assets/svg/icon-menu/carbon_information.svg"
                  class="mt-title-svg"
                  [stretch]="true"
                  [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 20,
                    'width.px': 20,
                    'margin-left.px': isFullSidebar? 0: 9
                  }">
              </svg-icon>
              <span class="w-100 f-size-17 pt-1 pl-2"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">
                {{"SIDEBAR.USAGE-INFORMATION"|translate}}</span>
              <div class="text-right pl-2"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far fa-angle-down"
                    *ngIf="!usageInformation"></i>
                <i class="far fa-angle-up"
                    *ngIf="usageInformation"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="usageInformation">
            <!-- Dashboard -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'kpi-dashboard']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/dashboard.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.DASHBOARD' | translate}}
                </span>
              </a>
            </div>

            <!-- Memo Report -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'memo-report']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/icon-report.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.MEMO-REPORT' | translate}}
                </span>
              </a>
            </div>

            <!-- Operation Log -->
            <div routerLinkActive="active"
                [routerLink]="['/', 'operation-log']"
                #rla="routerLinkActive"
                [style.--color]="themeList?.hovered_text_color"
                [style.--bg]="themeList?.hovered_text_color + '20'"
                *appCheckPermission="['Admin', 'Master Admin']"
                ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
                placement="right">
              <a class="list-group-item list-group-item-action px-4">
                <svg-icon src="assets/svg/icon-menu/task--view.svg"
                    [stretch]="true"
                    [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
                </svg-icon>
                <span class="pl-2 f-size-17"
                    *ngIf="isFullSidebar"
                    [ngStyle]="{
                      color: rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color
                    }">
                  {{'SIDEBAR.OPERATION-LOG' | translate}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- Package & Additionals -->
        <div class="custom-border"
            *appCheckPermission="['Admin', 'Master Admin', 'IT']"
            [ngStyle]="{'background': packageAddition? '': '' , 'color': themeList?.text_color}">
          <div routerLinkActive="active"
              class="mt-2 hasSubMenu"
              *appCheckPermission="['Admin', 'Master Admin', 'IT']"
              [style.--color]="themeList?.hovered_text_color"
              [style.--bg]="themeList?.hovered_text_color + '20'"
              placement="right"
              (click)="packageAddition=!packageAddition">
            <a class="list-group-item list-group-item-action px-4 position-relative d-flex">
              <span class="w-100 f-size-17 pt-1"
                  *ngIf="isFullSidebar"
                  [ngStyle]="{'color': themeList?.text_color}">{{'SIDEBAR.PACKAGE-AND-ADDITIONAL'|translate}}</span>
              <div class="text-right"
                  [ngStyle]="{'color': themeList?.text_color}">
                <i class="far fa-angle-down"></i>
              </div>
            </a>
          </div>

          <!-- usage information -->
          <ng-container *ngIf="packageAddition">

            <!-- Package Dashboard -->
            <ng-container *appFeatureFlag="'ddoc'">
              <div routerLinkActive="active"
                  [routerLink]="['/', 'usage-dashboard', 'package']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  [ngbTooltip]="'SIDEBAR.PACKAGE-USAGE'|translate"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/package-usage.svg"
                      [stretch]="true"
                      [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                    {{'SIDEBAR.PACKAGE-USAGE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>

            <!-- Usage Dashboard -->
            <ng-container>
              <div routerLinkActive="active"
                  [routerLink]="['/', 'usage-dashboard', 'usage']"
                  #rla="routerLinkActive"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  [ngbTooltip]="'SIDEBAR.CREDIT-USAGE'|translate"
                  placement="right">
                <a class="list-group-item list-group-item-action px-4">
                  <svg-icon src="assets/svg/icon-menu/credit-usage.svg"
                      [stretch]="true"
                      [svgStyle]="{ 'fill': (rla.isActive ? themeList?.hovered_text_color: themeList?.text_color),
        'height.px': 20, 'width.px': 20}">
                  </svg-icon>
                  <span class="pl-2 f-size-17"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{
                    color: rla.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                    {{'SIDEBAR.CREDIT-USAGE'|translate}}
                  </span>
                </a>
              </div>
            </ng-container>

          </ng-container>
        </div>

        <!-- Memo -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'memos']"
            #rla="routerLinkActive"
            [style.--color]="!isFolderId&&!checkIsTemplate? themeList?.hovered_text_color : ''"
            [style.--bg]="!isFolderId&&!checkIsTemplate?themeList?.hovered_text_color + '20': ''"
            *appCheckPermission="['General', 'HR']"
            ngbTooltip="{{'SIDEBAR.MEMOS' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <!-- Manage Users -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive && !checkIsTemplate && !isFolderId
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive && !checkIsTemplate &&!isFolderId
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'MEMOS.DOCUMENT-LIST' | translate}}
            </span>
          </a>
        </div>

        <!-- Template -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'memos', 'template']"
            #rlas="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            [ngClass]="checkIsTemplate?'active-template':''"
            *appCheckPermission="['General', 'HR']"
            ngbTooltip="{{'TEMPLATE.TEMPLATE-LIST' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <!-- Template -->
            <svg-icon src="assets/svg/icon-template.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': checkIsTemplate
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
              color:checkIsTemplate
                ? themeList?.hovered_text_color
                : themeList?.text_color
            }">
              {{'TEMPLATE.TEMPLATE-LIST' | translate}}
            </span>
          </a>
        </div>


        <!-- BOADCAST -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'broadcast']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['HR']"
            ngbTooltip="{{'SIDEBAR.BROADCAST-LIST' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <!-- BOADCAST -->
            <svg-icon src="assets/svg/icon-menu/memo.svg"
                [stretch]="true"
                [svgStyle]="{
                      'fill': rla.isActive
                        ? themeList?.hovered_text_color
                        : themeList?.text_color,
                      'height.px': 20,
                      'width.px': 20
                    }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.BROADCAST-LIST' | translate}}
            </span>
          </a>
        </div>

        <ng-container *appFeatureFlag="'close_feature'">
          <!-- Folder -->
          <ng-container>
            <div class="custom-border"
                *appCheckPermission="['General', 'HR']"
                [ngStyle]="{'background': manageFolder? '': '' , 'color': themeList?.text_color}">
              <!-- Folder -->
              <div routerLinkActive="active"
                  class="mt-2 hasSubMenu"
                  *appCheckPermission="['General', 'HR']"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  placement="right"
                  (click)="clickOpenFolder()">
                <a class="list-group-item list-group-item-action px-4 position-relative d-flex"
                    [style.padding-left]="'1.7rem !important'">
                  <svg-icon src="assets/svg/icon-folder.svg"
                      class="mt-title-svg"
                      [stretch]="true"
                      [svgStyle]="{
                    'fill': rla?.isActive
                      ? themeList?.hovered_text_color
                      : themeList?.text_color,
                    'height.px': 14,
                    'width.px': 16,
                    'margin-left.px': isFullSidebar? 0: 9
                  }">
                  </svg-icon>
                  <span class="w-100 f-size-17 pt-1"
                      [style.padding-left]="'0.8rem !important'"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{'color': themeList?.text_color}">{{'FOLDER.FOLDER'|translate}}</span>
                  <div class="text-right ml-2"
                      [ngStyle]="{'color': themeList?.text_color}">
                    <i class="far fa-angle-down"
                        *ngIf="!manageFolder"></i>
                    <i class="far fa-angle-up"
                        *ngIf="manageFolder"></i>
                  </div>
                </a>
              </div>

              <!-- Folder List -->
              <ng-container *ngIf="manageFolder">
                <div class="custom-folder">
                  <ng-container *ngFor="let folder of folderList">
                    <div routerLinkActive="active"
                        #rla="routerLinkActive"
                        [style.--color]="isFolderId === folder.id?themeList?.hovered_text_color:''"
                        [style.--bg]="isFolderId === folder.id?themeList?.hovered_text_color + '20':''"
                        [ngClass]="isFolderId === folder.id?'active-template':''"
                        [style.padding-left.rem]="'1.4'"
                        *appCheckPermission="['General', 'HR']"
                        placement="right">
                      <a class="list-group-item list-group-item-action px-4"
                          (click)="navigateToPageFolder(folder.id, folder.name, $event)">
                        <div class="f-size-17 text-truncate"
                            [style.padding-left]="'0.8rem !important'"
                            *ngIf="isFullSidebar"
                            [ngStyle]="{
                    color: isFolderId === folder.id
                      ? themeList?.hovered_text_color
                      : themeList?.text_color
                  }">
                          {{folder.name}}
                            <ng-container *ngTemplateOutlet="moveFolderTemplate;
                          context: { $implicit: folder }
                          "></ng-container>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </div>

              </ng-container>


              <!-- Create Folder -->
              <ng-container *ngIf="manageFolder">
                <div (click)="openFolderModal(createFolderModal)"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    [style.--create-color]="themeList?.text_color"
                    [style.padding-left.rem]="'1.6'"
                    *appCheckPermission="['General', 'HR']"
                    placement="right">
                  <a class="list-group-item list-group-item-action px-4">
                    <span class="pl-2 f-size-17 create-folder"
                        *ngIf="isFullSidebar">
                      {{"FOLDER.CREATE-FOLDER" | translate}}
                    </span>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container></ng-container>


        <!-- Config -->
        <div routerLinkActive="active"
            [routerLink]="['/', 'configuration']"
            #rla="routerLinkActive"
            [style.--color]="themeList?.hovered_text_color"
            [style.--bg]="themeList?.hovered_text_color + '20'"
            *appCheckPermission="['Admin', 'Master Admin']"
            ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
            placement="right">
          <a class="list-group-item list-group-item-action px-4">
            <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
                [stretch]="true"
                [svgStyle]="{
                  'fill': rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color,
                  'height.px': 20,
                  'width.px': 20
                }">
            </svg-icon>
            <span class="pl-2 f-size-17"
                *ngIf="isFullSidebar"
                [ngStyle]="{
                  color: rla.isActive
                    ? themeList?.hovered_text_color
                    : themeList?.text_color
                }">
              {{'SIDEBAR.CONFIGURATION' | translate}}
            </span>
          </a>
        </div>
      </div>
      <div class="position-version"
          [ngStyle]="{'left': isFullSidebar? '18px':'25px'}">
        <div class="f-size-17 pl-0">
          <div ngbDropdown>
            <button class="btn-support"
                ngbTooltip="{{'LOGIN.Support'|translate}}"
                [ngStyle]="{color: themeList?.text_color }"
                id="dropdownBasic1"
                ngbDropdownToggle>
              <svg-icon src="assets/svg/login/help.svg"
                  [svgStyle]="{'fill': themeList?.text_color}"
                  [stretch]="true"
                  class="mr-2">
              </svg-icon>
              <span *ngIf="isFullSidebar">{{"LOGIN.Support"|translate}}</span>
            </button>
            <div ngbDropdownMenu
                aria-labelledby="dropdownBasic1">
              <button class="border-bottom"
                  (click)="redirectToExternalUrl('faq')"
                  ngbDropdownItem>{{"LOGIN.FAQ"|translate}}</button>
              <button class="border-bottom"
                  (click)="redirectToExternalUrl('feedback')"
                  ngbDropdownItem>{{"LOGIN.Feedback"|translate}}</button>
              <button ngbDropdownItem
                  (click)="redirectToExternalUrl('rating')">{{"LOGIN.Rating"|translate}}</button>
            </div>
          </div>

        </div>
        <span class="pl-2 f-size-17"
            *ngIf="isFullSidebar && version"
            [ngStyle]="{
                  color: themeList?.text_color
                }">
          Version {{version}}
        </span>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <app-navbar [profile]="profile"></app-navbar>

    <ng-container *appFeatureFlag="'ddoc'">
      <div class="col-12 px-0"
          *ngIf="showWarning &&
            profile?.otp_type === null &&
            certificateStatus &&
            certificateStatus !== 'not_created' &&
            (checkRouteActive('/pending-approval') ||
              checkRouteActive('/news-feed') ||
              checkRouteActive('/memos'))">
        <div class="box-warning col-12 d-flex">
          <i class="fal fa-exclamation-triangle align-self-center mr-2"
              style="color:rgb(216, 187, 24)"></i>
          <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>
          <button class="btn align-self-center ml-3"
              [routerLink]="['/', 'profile-detail', 'otp']"
              appSetTheme
              type="button">
            <span style="font-size: 13px">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
          </button>
          <a class="text-black p-2 align-self-center ml-auto"
              href="javascript:void(0)"
              role="button"
              (click)="showWarning = false">
            <i class="fal fa-times"></i>
          </a>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="col-12 px-0"
          *ngIf="showWarningCertificate && certificateDetail?.is_expiring">
        <div class="box-warning col-12 d-flex">
          <i class="fal fa-exclamation-triangle align-self-center mr-2"
              style="color:rgb(216, 187, 24)"></i>
          <span class="align-self-center">
            {{"CERTIFICATE.Certificate"|translate}}
            {{certificateDetail?.certificate_name}}
            {{"CERTIFICATE.that use to seal the document will expire on"|translate}}
            {{certificateDetail?.date_range?.upper|date: 'dd/MM/yyyy'}}
            {{"CERTIFICATE.Please create a new certificate before the expiration date"|translate}}
          </span>
          <button class="btn align-self-center ml-3"
              [routerLink]="['/', 'certificate']"
              appSetTheme
              type="button">
            <span style="font-size: 13px">
              {{"CERTIFICATE.Go to manage certificate"|translate}}
            </span>
          </button>
          <a class="text-black p-2 align-self-center ml-auto"
              href="javascript:void(0)"
              role="button"
              (click)="showWarningCertificate = false">
            <i class="fal fa-times"></i>
          </a>
        </div>
      </div>
    </ng-container>

    <div class="container-fluid overflow-auto p-0">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>


<ng-template #createFolderModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{"FOLDER.CREATE-FOLDER"| translate}}</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeFolderModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4 custom-box text-gray">
    <ng-container>
      <div class="col-12 p-0">
        <label>{{"FOLDER.FOLDER-NAME"|translate}}</label>
        <input type="text"
            class="form-control"
            placeholder="Folder"
            [(ngModel)]="folderName">
      </div>

    </ng-container>
    <div class="justify-content-end mt-5 btn-container text-right"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <button type="button"
          class="btn btn-outline w-auto"
          (click)="closeFolderModal()">{{ "APPROVAL.CANCEL" | translate }}</button>
      <button type="button"
          class="btn ml-2"
          appSetTheme
          (click)="createFolder()">{{ 'MEMOS.CONFIRM' | translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #moveFolderTemplate
    let-moveFolderTemplate>
  <div ngbDropdown
      #myDropdown="ngbDropdown"
      container="body"
      [autoClose]="true"
      class="d-inline-block custom-dropdown edit-folder">

    <div class=""
        appSetTheme
        id="dropdownBasic1"
        ngbDropdownToggle>
      <span class="pr-2 item-folder"><i class="fa fa-ellipsis-v"
            [style.font-size.px]="14"
            aria-hidden="true"></i></span>
      <!-- <svg-icon [src]="'assets/svg/icon-arrow-down.svg'"
                [svgStyle]="{'width.px': 20, 'height.px': 20, 'margin-top.px': -1}"></svg-icon> -->
    </div>
    <div ngbDropdownMenu
     class="py-0"
        aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem
          (click)="openModal(editFolderModal, moveFolderTemplate)"
          class="border-bottom py-2">
        {{"FOLDER.Edit Folder"|translate}}
      </button>


      <button ngbDropdownItem
          class="py-2"
          [style.color]="'#D92D20'"
          (click)="openModal(deleteFolderModal, moveFolderTemplate)"
          [disabled]="isLoading">
        <div>
          {{ 'FOLDER.Delete Folder' |translate }}
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editFolderModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{'FOLDER.Edit Folder'| translate}}</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeFolderModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4 custom-box text-gray">
    <ng-container>
      <div class="col-12 p-0">
        <label>{{"FOLDER.FOLDER-NAME"|translate}}</label>
        <input type="text"
            class="form-control"
            placeholder="Folder"
            [(ngModel)]="folderNameChange">
      </div>

    </ng-container>
    <div class="justify-content-end mt-5 btn-container text-right"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <button type="button"
          class="btn btn-outline w-auto"
          (click)="closeFolderModal()">{{ "APPROVAL.CANCEL" | translate }}</button>
      <button type="button"
          class="btn ml-2"
          appSetTheme
          (click)="editNameFolder()">{{ 'MEMOS.CONFIRM' | translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #deleteFolderModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header red">
    <h4 class="modal-title">
      {{'FOLDER.Delete Confirmation' | translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeFolderModal()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-center">
    <h4 [style.font-size.px]="20">{{'FOLDER.DELETE-FILE-FOLDER' | translate}}</h4>
    <label [style.color]="'#747474'"
        [style.line-height.px]="26"
        for="">{{"FOLDER.DELETE-FILE-FOLDER-2"|translate}}</label>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline btn-w-80"
        [style.--c]="themeList?.header_table_color"
        (click)="closeFolderModal()">
      {{'MEMOS.CANCEL'|translate}}
    </button>
    <button class="btn btn-w-80"
        type="button"
        appSetTheme
        (click)="deleteFolder()">
      {{'MEMOS.CONFIRM'|translate}}
    </button>
  </div>
</ng-template>