import { LOADetail } from '../../loa/shared/loa.model';
import { Marker } from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

export interface ApproverDropdown {
  full_name: string;
  person: number;
  signature?: string;
  full_name_th: string;
  company_name?: string;
}

export interface DepartmentDisplay {
  display_name: string;
  display_name_en: string;
  department_name: string;
  department_name_en: string;
}

export interface CreatedUserDepartment {
  display_name: string;
  display_name_en: string;
  department_name: string;
  department_name_en: string;
}

export interface MemoList {
  id: number;
  memo_number: string;
  memo_type: string;
  memo_type_display: string;
  subject: string;
  department_display: DepartmentDisplay;
  signed_document: string;
  created_at: Date;
  attachment_count: number;
  comment_count: number;
  status: string;
  status_display: string;
  created_user_name: string;
  created_user_name_th: string;
  is_cc: boolean;
  is_mentioned: boolean;
  is_revised: boolean;
  published_at?: Date;
  version: number;
  urgent: boolean;
  upload_type?: string;
  created_user_department: CreatedUserDepartment;
  checked?: boolean;
  expiry_date?: string;
}

export interface MemoListDetail {
  next?: any;
  previous?: any;
  count: number;
  results: MemoList[];
  all_count: number;
  draft_count: number;
  pending_count: number;
  rejected_count: number;
  approved_count: number;
  terminated_count: number;
  trashed_count: number;
  expired_count: number;
  cc_count: number;
  checkbox?: boolean;
}

export interface MemoListResponse {
  count: number;
  next: string;
  previous: any;
  results: ResultMemo[];
  draft_count: number;
  pending_count: number;
  rejected_count: number;
  approved_count: number;
  terminated_count: number;
}

export interface ResultMemo {
  id: number;
  created_name: string;
  created_job_position: string;
  updated_name: string;
  attachment_count: number;
  current_level: number;
  current_level_signature_require?: boolean;
  max_level: number;
  department_name: string;
  department_name_en: string;
  comment_count: number;
  loa_level_name: string[];
  loa_level_delegate: boolean[];
  tooltip_data: string[];
  unread_comment_count: number;
  unread_attachment_count: number;
  otp_enable: boolean;
  count_page?: number;
  can_recall: boolean;
  count_history: number;
  memo_template_id?: number;
  is_custom_loa?: boolean;
  entity_list: EntityList[];
  folder_list: string[][];
  folder_id_list: number[][];
  created_at: string;
  memo_number?: string;
  is_custom_memo_number: boolean;
  subject?: string;
  to: any;
  cc?: string;
  cc_list?: number[];
  detail: any;
  loa?: Loa[];
  due_date: any;
  name?: string;
  reason?: string;
  signed_document?: string;
  status: string;
  approve_step: number;
  send_total: number;
  created_date: any;
  publish_at?: string;
  approve_at?: string;
  customer_email: any;
  customer_phone: any;
  customer_signature: any;
  is_read: boolean;
  is_notify_72hrs: boolean;
  color_code: any;
  word_cloud_pdf?: string;
  word_cloud_image: any;
  memo_type: string;
  signature_position?: SignaturePosition[];
  tooltip_value?: string[];
  memo_template: string;
  is_save_template: boolean;
  is_permission: boolean;
  version: number;
  ready: boolean;
  reference?: string[];
  from_name: string;
  from_position: string;
  summary_note?: string;
  created_user: number;
  updated_user: number;
  to_profile: any;
  loa_group?: number;
  department?: number;
  level?: number;
  announcement: number[];
  entity: number[];
}

export interface EntityList {
  id: number;
  name: string;
}

export interface Loa {
  title: string;
  members: any[];
  user_type: string;
  min_approve_count: number;
  signature_required: boolean;
}

export interface SignaturePosition {
  name: string;
  name_th?: string;
  type?: string;
  sequence: number;
  ddoc_enable?: boolean;
  ddoc_use_28?: boolean;
  m28_signature_preview?: string;
  positions?: Position[];
  positions_page?: number[];
  level?: number;
  signature?: string;
  member_seq?: number;
  approved_date_positions?: any;
  display_name?: string;
  display_name_th?: string;
  signatureRequire?: any;
  member_index?: number;
  member_sig_req?: any;
  no?: number;
  fe_mem_id?: string;
  stamp_positions?: any;
  drawing_positions?: any;
  form_attachments?: any;
  form_text_positions?: any;
  form_checkbox_positions?: any;
  none_user_email?: string;
  user_id?: number | any;
  company_name?: string;
}

export interface Position {
  H?: number;
  W?: number;
  X: number;
  Y: number;
  id: string;
  page: number;
  color?: string;
  string?: string;
  fontSize?: number;
  fontStyle: FontStyle;
  initialPos: InitialPos;
  sub_signature?: number;
  colorForRender?: ColorForRender;
  width?: number;
  height?: number;
  isStamp?: boolean;
  onEdit?: boolean;
  folderId?: number;
  stampPic?: string;
  dateFormat?: string;
}

export interface FontStyle {
  name: string;
  value: string;
}

export interface InitialPos {
  x: number;
  y: number;
}

export interface ColorForRender {
  b: number;
  g: number;
  r: number;
}

export interface MemoDepartment {
  id: number;
  sequence: number;
  name: string;
  name_en: string;
  code: string;
  company: any;
  loa: Loa[];
}

export interface Loa {
  id: number;
  levels: Level[];
  count: number;
  department_name: string[];
  department_name_en: string[];
  name: string;
  group_type: number;
  max_level: number;
  memo_types: any;
  is_custom: boolean;
  levels_name: string[];
  delegate_value: boolean[];
  departments: number[];
}

export interface MemberLoa {
  id: number;
  person_name: string;
  person: number;
  job_position_name: string;
  sequence: number;
  is_delegate: boolean;
  level: number;
}

export interface LevelLoa {
  id: number;
  members: MemberLoa[];
  count: number;
  level: number;
  min_approve_count: number;
  signature_required: boolean;
  site: number;
  group: number;
}

export interface LoaGroupDetail {
  id: number;
  levels: LevelLoa[];
  name: string;
  is_custom: boolean;
  memo_type?: any;
  levels_name: string[];
  max_level: number;
  site: number;
  department: number[];
}

export interface Level {
  id: number;
  members: Member[];
  count: number;
  user_type: string;
  level: number;
  min_approve_count: number;
  signature_required: boolean;
  group: number;
}

export interface Member {
  id: number;
  profile_name: string;
  job_position: string;
  signature: string;
  sequence: number;
  is_delegate: boolean;
  loa_group: number;
  loa_level: number;
  profile: number;
  person_name?: string;
  person_signature?: string;
}

export interface UploadMemoType {
  id?: number;
  name: string;
  document_prefix?: string;
  memo_number_pattern?: string;
}

export interface AcknowledgmentPeople {
  id: number;
  person: AcknowledgmentPerson;
  person_department: string;
  acknowledged: boolean;
  comment: string;
}

export interface AcknowledgmentPerson {
  id: number;
  full_name: string;
}

export interface MemoDetail {
  id: number;
  department_display: DepartmentDisplay;
  memo_type_display: string;
  attachment_count: number;
  comment_count: number;
  status_display: string;
  created_user_name: string;
  created_user_name_th: string;
  created_user_department: CreatedUserDepartment;
  is_cc: boolean;
  is_mentioned: boolean;
  is_revised: boolean;
  current_level: number;
  current_level_signature_required: any;
  max_level: any;
  loa_level_name: any[];
  unread_comment_count: number;
  unread_attachment_count: number;
  can_publish: boolean;
  can_edit: boolean;
  can_recall: boolean;
  can_approve: boolean;
  can_reject: boolean;
  can_access_info: boolean;
  can_upload_attachment: boolean;
  can_comment: boolean;
  can_revise: boolean;
  cc_list: any[];
  due_date: unknown;
  email_setting?: any;
  loa_group: LOADetail;
  custom_loa_group?: any;
  attachments: any[];
  rejected_level: any;
  revised_memo: any;
  table_data: any;
  otp_enable: boolean;
  acknowledgment_need_count: number;
  acknowledged_count: number;
  internal: Internal;
  created_at: string;
  memo_type: string;
  memo_number: string;
  is_custom_memo_number: boolean;
  subject: string;
  urgent: boolean;
  reason: string;
  version: number;
  signed_document: string;
  status: string;
  reminded: boolean;
  hard_reminded: boolean;
  is_only_related: boolean;
  is_require_acknowledge: boolean;
  published_at: any;
  start_working_at: any;
  finished_at: any;
  reference_memo_numbers: any[];
  created_user: number;
  site: number;
  department: number;
  loa_level: any;
  announcements: any[];
  upload?: UploadMemo;
  expiry_date?: string;
  broadcast?: any;
}

export interface DepartmentDisplay {
  display_name: string;
  display_name_en: string;
  department_name: string;
  department_name_en: string;
}

export interface CreatedUserDepartment {
  display_name: string;
  display_name_en: string;
  department_name: string;
  department_name_en: string;
}

export interface Internal {
  id: number;
  to_person: any;
  detail: string;
  site: number;
}

export interface CommentList {
  id: number;
  short_name: string;
  extension: string;
  file_size: number;
  created_person: number;
  created_user_name: string;
  time_since: string;
  created_user_photo: string;
  message: string;
  file: any;
  file_name: string;
  site: number;
  memo: number;
}

export interface Comment {
  mentioned_people: any[];
  memo: number;
  message: string;
}

export interface TemplateComponent {
  memoType: string;
  component: ComponentData[];
  typeDropdown?: string;
  api?: string;
}

export interface ComponentData {
  componentName: string;
  isMemoTypeField: boolean;
  controlName: string;
  title: string;
  placeholder: string;
  half: boolean;
  data?: string;
  type?: string;
  limit?: number;
  dropdown: DropdownData;
  disable: boolean;
  customComponent: any[];
  /**
   * - Assign `undefined` if you want to disable the number label.
   * - Assign `null` if you want to only the gap as the number label.
   */
  numberLabel?: number;
  /**
   * Specify the session name for grouping all components have `componentName`.
   * If this property is undefined the component is grouped in the undefined session.
   */
  session?: string | ComponentSessionConfig;
  subControlName?: { [key: string]: string };
}

export interface ComponentSessionConfig {
  sessionName: string;
  /** Set the key for translation by ngx-translate */
  translate?: string;
}

export interface DropdownData {
  items?: any[];
  filterKey: string[];
  filter_id: string[];
  multiple: boolean;
  typeData: string;
  label?: string;
  default?: boolean;
  addTag?: boolean;
}

export interface MemoData {
  memo: any;
  form: any;
}

export interface AttachmentDetail {
  id: number;
  short_name: string;
  extension: string;
  file_size: number;
  remark: string;
  file_name: string;
  file: string;
  site: number;
  memo: number;
  fe_attach_id?: string;
  created_person?: number;
  created_user_name?: string;
  created_user_name_th?: string;
  created_user_photo?: string;
  attach_name?: string;
  isDownload?: boolean;
}

export interface DepartmentDisplay {
  display_name: string;
  display_name_en: string;
  department_name: string;
  department_name_en: string;
}

export interface ReferencesDetail {
  id: number;
  memo_number: string;
  memo_type: string;
  memo_type_display: string;
  subject: string;
  department_display: DepartmentDisplay;
  signed_document: string;
}

export interface Actor {
  id: number;
  full_name: string;
}

export interface HistoryDetail {
  type?: string;
  personTo?: string;
  personFrom?: string;
  transfer_type?: string;
  id: number;
  actor: Actor;
  actor_photo?: any;
  created_at: Date;
  action: string;
  detail: string;
}

export interface MemoDetail {
  id: number;
  email_detail: string;
  email_subject: string;
  notification_language: string;
}

export interface UploadMemo {
  count_page: number;
  numbers_of_signature: number;
  signature_position: Marker[];
  site: number;
  upload_memo_type: number;
  upload_memo_template: number;
  uploaded_pdf: string;
  id?: number;
}

export interface DownloadWithAttachmentsPayload {
  memo_list: number[];
  include_documents: boolean;
  include_certs: boolean;
  include_respondents?: boolean;
  attachments: number[];
}

export interface DownloadInfoAttachment {
  id: number;
  file_name: string;
}

export interface DownloadInfo {
  memo_id: number;
  memo_number: string;
  attachments: DownloadInfoAttachment[];
}
