import { LoginPayload } from './auth.model';

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: LoginPayload) {}
}

export class GetUserById {
  static readonly type = '[Auth] Get User';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class ClearState {
  static readonly type = '[Auth] Clear State';
}

export class SaveUserDetail {
  static readonly type = '[Auth] SaveUserDetail';

  constructor(public value: any, public name: string) {}
}
